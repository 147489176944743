import { uploadFile } from 'reactelements/src/hooks/useFileUpload/uploadHelper';
import { newCustomReportResultDb } from '../NewCustomReportResult/newCustomReportResultDexie';
import { newExpenseDb } from '../NewExpense/newExpenseDexie';
import { newFileSystemDb } from '../NewFileSystem/newFileSystemDexie';
import { newSalesItemDb } from '../NewSalesItem/newSalesItemDexie';
import { newCRMDb } from '../NewCRM/newCRMItemDexie';
import { newSyncDataDb } from './newSyncDexie';
import { newEventActionsDb } from '../NewEventActionCollection/newEventActionCollectionDexie';
import { newCRMActionsDb } from '../NewCRMActions/newCRMActionDexie';
import DynTableConst from '../../Services/Constants';
import axios from 'axios';
import { parseSavedReportResults } from 'reactelements/src/modules/CustomReport/utils/reportHelpers';
import { startEventSyncAction } from '../NewEvent/actions';
import ObjectID from 'bson-objectid';
import { getEventsFetch } from '../NewEvent/helpers';
import { createSyncHistoryEntry } from './helpers';
import { generateRandomHash } from 'reactelements/src/modules/CustomReportDependentFields/keygen';
import { verifyDeviceSyncHash } from '../../Services/pwaSyncControlHashHelpers';
import { SalesItem } from '../../../reactelements/src/utils/salesitem';
import { newEventDb } from '../NewEvent/newEventsDexie';
import { generatePOS } from '../../Components/DashboardItems/LeadDetailPage';
import { CRMData } from 'reactelements/src/modules/ViewEditCrm/types';
import { getAllSyncStatuses } from '../helpers';

const newSyncDataActionTypes = {
    START_SYNC_UPLOAD_TIME: 'START_SYNC_UPLOAD_TIME',
    ADD_SYNC_DATA: 'ADD_SYNC_DATA',
    START_SYNC_DOWNLOAD_TIME: 'START_SYNC_DOWNLOAD_TIME',
    LOAD_SYNCDATA: 'SYNCDATA_LOAD_SYNCDATA',
    LOAD_PENDING_SYNCDATA: 'SYNCDATA_LOAD_PENDING_SYNCDATA',
    RESET_SYNC_LOGS: 'SYNCDATA_RESET_SYNC_LOGS',
    UPDATE_SYNCING: 'SYNCDATA_UPDATE_SYNCING',
    ADD_SYNC_LOG: 'SYNCDATA_ADD_SYNC_LOG',
    ADD_SYNC_ERROR: 'SYNCDATA_ADD_SYNC_ERROR',
};

// adding timestamps of the last upload and last download time to dexie
const addActionSyncTimeToDexie = ({ type, payload }: { type: string; payload: any }) => {
    return {
        type,
        payload,
    };
};

export const getSyncTime = (user: string) => async (dispatch: any) => {
    try {
        const userData = await newSyncDataDb.syncData.get({ user: user });
        if (userData && userData._id) {
            dispatch(
                addActionSyncTimeToDexie({
                    type: newSyncDataActionTypes.ADD_SYNC_DATA,
                    payload: {
                        uploadTime: userData.uploadTime,
                        downloadTime: userData.downloadTime,
                    },
                }),
            );
        } else {
            dispatch(
                addActionSyncTimeToDexie({
                    type: newSyncDataActionTypes.ADD_SYNC_DATA,
                    payload: {
                        uploadTime: null,
                        downloadTime: null,
                    },
                }),
            );
        }
    } catch (error) {
        console.log(error, 'error while getting sync time');
    }
};

export const addUploadTimeToDexie = (user: string) => async (dispatch: any) => {
    // find the document that matches for the user
    // update the document if found
    // if not found create a new one
    try {
        const date = new Date();
        const userData = await newSyncDataDb.syncData.get({ user: user });

        if (userData && userData.user) {
            await newSyncDataDb.syncData.update(userData._id, {
                uploadTime: date,
            });
        } else {
            await newSyncDataDb.syncData.add({
                _id: new ObjectID().str,
                user,
                version: '2.0',
                uploadTime: date,
                downloadTime: null,
            });
        }
        dispatch(addActionSyncTimeToDexie({ type: newSyncDataActionTypes.START_SYNC_UPLOAD_TIME, payload: date }));
    } catch (error) {
        console.log(error, 'error adding upload time');
    }
};

export const addDownloadTimeToDexie = (user: string) => async (dispatch: any) => {
    // find the document that matches for the user
    // update the document if found
    // if not found create a new on
    try {
        const date = new Date();
        const userData = await newSyncDataDb.syncData.get({ user: user });

        if (userData && userData.user) {
            await newSyncDataDb.syncData.update(userData._id, {
                downloadTime: date,
            });
        } else {
            await newSyncDataDb.syncData.add({
                _id: new ObjectID().str,
                user,
                version: '2.0',
                uploadTime: null,
                downloadTime: date,
            });
        }
        dispatch(addActionSyncTimeToDexie({ type: newSyncDataActionTypes.START_SYNC_DOWNLOAD_TIME, payload: date }));
    } catch (error) {
        console.log(error, 'Error Adding Download Time');
    }
};

// pending sync queries
export const eventPendingSyncDataCount = async (eventId: string) => {
    if (window.isloggingOut) return;

    const salesItems = await newSalesItemDb.salesItems
        .filter(s => (!!s.isNewDoc || !!s.syncPending) && s.event === eventId)
        .count()
        .catch(e => {
            console.error(e);
        });
    const expenses = await newExpenseDb.expenses
        .filter(s => (!!s.isNewDoc || !!s.syncPending) && s.event === eventId)
        .count()
        .catch(e => {
            console.error(e);
        });
    const customReportResults = await newCustomReportResultDb.customReportResults
        .filter(s => (!!s.isNewDoc || !!s.syncPending) && s.event === eventId)
        .count()
        .catch(e => {
            console.error(e);
        });
    const eventActions = await newEventActionsDb.eventActions
        .filter(s => s.synced === false && s.action._id === eventId)
        .count()
        .catch(e => {
            console.error(e);
        });

    return (salesItems || 0) + (expenses || 0) + (customReportResults || 0) + (eventActions || 0);
};

export const loadPendingSyncData = async () => {
    if (window.isloggingOut) return;

    const salesItems = await newSalesItemDb.salesItems
        .filter(s => !!s.isNewDoc || !!s.syncPending)
        .count()
        .catch(e => {
            console.error(e);
        });
    const expenses = await newExpenseDb.expenses
        .filter(s => !!s.isNewDoc || !!s.syncPending)
        .count()
        .catch(e => {
            console.error(e);
        });
    const crmItems = await newCRMDb.crmItems
        .filter(s => !!s.isNewDoc || !!s.syncPending)
        .count()
        .catch(e => {
            console.error(e);
        });
    const customReportResults = await newCustomReportResultDb.customReportResults
        .filter(s => !!s.isNewDoc || !!s.syncPending)
        .count()
        .catch(e => {
            console.error(e);
        });
    const images = await newFileSystemDb.fileSystems
        .filter(f => !f.syncPending)
        .count()
        .catch(e => {
            console.error(e);
        });
    const eventActions = await newEventActionsDb.eventActions
        .filter(s => s.synced === false)
        .count()
        .catch(e => {
            console.error(e);
        });
    const crmActions = await newCRMActionsDb.crmActions
        .filter(s => s.synced === false)
        .count()
        .catch(e => {
            console.error(e);
        });

    return { salesItems, expenses, customReportResults, images, eventActions, crmItems, crmActions };
};

export const sendPayloadToGlitchGuruService = async (hash: string, key: string, payload: string, errorWhileReporting: any) => {
    try {
        var urlencoded = new URLSearchParams();
        urlencoded.append('url', window.location.origin);
        urlencoded.append('key', key);
        urlencoded.append('data', payload);

        await axios.post(`https://glitch.empplan.io/api/pwadb/${window.userabstraction?._id}/${hash}`, urlencoded, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        });
    } catch (error) {
        console.error('sendPayloadToGlitchGuruService', JSON.stringify({ error, response: (error as any)?.response }));
        errorWhileReporting?.();
    }
};

export const reportAllDataFromDatabasesToGlitchService = async (reportProgress?: any, optionalExtraPayload?: any) => {
    const hash = generateRandomHash();
    let errorCount = 0;

    const errorWhileReporting = () => {
        errorCount++;
    };

    const customReportResultsCount = await newCustomReportResultDb.customReportResults.count().catch(e => {
        console.error(e);
    });
    const imagesCount = await newFileSystemDb.fileSystems.count().catch(e => {
        console.error(e);
    });
    const eventActionsCount = await newEventActionsDb.eventActions.count().catch(e => {
        console.error(e);
    });
    const crmActionsCount = await newCRMActionsDb.crmActions.count().catch(e => {
        console.error(e);
    });
    let totalCount =
        (customReportResultsCount || 0) + (imagesCount || 0) + (eventActionsCount || 0) + (crmActionsCount || 0);
    let currentCount = 0;

    if (optionalExtraPayload) {
        totalCount += 1;
        await sendPayloadToGlitchGuruService(hash, 'reduxState', optionalExtraPayload, errorWhileReporting);
        currentCount++;
        reportProgress?.(currentCount / totalCount);
    }

    // const salesItems = await newSalesItemDb.salesItems.filter(s => !!s.isNewDoc || !!s.syncPending).toArray();
    // for await (const payload of salesItems) {
    //     await sendPayloadToGlitchGuruService(hash, payload._id as string, JSON.stringify(payload));
    // }
    // const expenses = await newExpenseDb.expenses.filter(s => !!s.isNewDoc || !!s.syncPending).toArray();
    // for await (const payload of expenses) {
    //     await sendPayloadToGlitchGuruService(hash, payload._id as string, JSON.stringify(payload));
    // }
    const customReportResults = await newCustomReportResultDb.customReportResults
        // .filter(s => !!s.isNewDoc || !!s.syncPending)
        .toArray();
    for await (const payload of customReportResults) {
        await sendPayloadToGlitchGuruService(hash, payload._id as string, JSON.stringify(payload), errorWhileReporting);
        currentCount++;
        reportProgress?.(currentCount / totalCount);
    }
    const eventActions = await newEventActionsDb.eventActions
        // .filter(s => !s.synced)
        .toArray();
    for await (const payload of eventActions) {
        await sendPayloadToGlitchGuruService(hash, payload._id as string, JSON.stringify(payload), errorWhileReporting);
        currentCount++;
        reportProgress?.(currentCount / totalCount);
    }
    const crmActions = await newCRMActionsDb.crmActions.toArray();
    for await (const payload of crmActions) {
        await sendPayloadToGlitchGuruService(hash, payload._id as string, JSON.stringify(payload), errorWhileReporting);
        currentCount++;
        reportProgress?.(currentCount / totalCount);
    }
    const images = await newFileSystemDb.fileSystems
        // .filter(f => !!f.syncPending)
        .toArray();
    for await (const payload of images) {
        await sendPayloadToGlitchGuruService(hash, payload._id as string, JSON.stringify(payload), errorWhileReporting);
        currentCount++;
        reportProgress?.(currentCount / totalCount);
    }

    return { hash, errorCount };
};

/**
 * Sync controller functions
 */
interface SyncToServerControlOptions {
    onlySyncToServer?: boolean;
    postSyncToServerCallback?: any;
    resetDeviceHashInfo?: any;
}

export const startSyncToServer = (controlOptions?: SyncToServerControlOptions) => async (dispatch: any) => {
    console.log('initSyncToServer');
    dispatch({ type: newSyncDataActionTypes.RESET_SYNC_LOGS });

    if (!window.userabstraction?._id) {
        console.error('Failed to sync cause no userabstraction bound to window');
        return;
    }

    const deviceSyncHash = await verifyDeviceSyncHash();
    if (!deviceSyncHash?.verified) {
        console.error('Older device hash, exiting sync');
        createSyncHistoryEntry('SyncFailedHashCheck', { deviceSyncHash });
        // this will force app to verify sync hash with server and show relevant dialogs
        controlOptions?.resetDeviceHashInfo?.();
        return;
    }

    const syncHash = generateRandomHash();
    createSyncHistoryEntry('SyncUpStart', { syncHash, deviceSyncHash, syncTimestamps: getAllSyncStatuses() });

    dispatch({ type: newSyncDataActionTypes.UPDATE_SYNCING, payload: 'UPLOADING' });

    console.log('startSyncingToServer');

    let upSyncFailed = 0;
    const setUpSyncFailed = () => {
        upSyncFailed++;
    };

    const eventsForPostSyncTasks: Array<string> = [];
    const addEventForPostSyncTasks = (eventId?: any) => {
        const eId: string = eventId._id || eventId || '';
        if (eId && !eventsForPostSyncTasks.includes(eId)) {
            eventsForPostSyncTasks.push(eId);
        }
    };

    try {
        await syncCustomReportResultsToServer(dispatch, setUpSyncFailed, { addEventForPostSyncTasks });
        await syncCrmItemsToServer(dispatch, setUpSyncFailed);
        await syncExpensesToServer(dispatch, setUpSyncFailed, { addEventForPostSyncTasks });
        await syncSalesItemsToServer(dispatch, setUpSyncFailed, { addEventForPostSyncTasks });
        await syncEventActions(dispatch, setUpSyncFailed, { addEventForPostSyncTasks });
        await syncCRMActions(dispatch, setUpSyncFailed, { addEventForPostSyncTasks });

        await runPostSyncUpEventTasks(eventsForPostSyncTasks);

        // add the upload done timestamp here
        dispatch(addUploadTimeToDexie(window.userabstraction?._id));

        dispatch(loadPendingSyncData);
        if (!upSyncFailed && controlOptions?.postSyncToServerCallback) {
            setTimeout(() => {
                controlOptions.postSyncToServerCallback();
            }, 1000);
        }
    } catch (error) {
        console.error('Failed to sync up to server', window.userabstraction?._id, error);
        dispatch({
            type: newSyncDataActionTypes.ADD_SYNC_ERROR,
            payload: error || 'Error handled but no log available',
        });
        return;
    }

    console.log('done syncing to server');
    createSyncHistoryEntry('SyncUpEnd', { syncHash, deviceSyncHash, syncErrorCount: upSyncFailed });
    setTimeout(() => {
        dispatch({ type: newSyncDataActionTypes.UPDATE_SYNCING, payload: 'READY' });
    }, 5000);

    if (upSyncFailed) return;

    console.log('Running cleanEventActions');
    await cleanEventActions(dispatch);
    console.log('Running cleanCRMActions');
    await cleanCRMActions(dispatch);

    if (controlOptions?.onlySyncToServer) return;

    console.log('Running cleanNewFileSystem');
    createSyncHistoryEntry('Deleting IndexDB After SyncSuccess Without Error', {
        syncHash,
        deviceSyncHash,
        syncErrorCount: upSyncFailed,
    });
    await cleanNewFileSystem(dispatch);

    createSyncHistoryEntry('SyncDownStart', { syncHash, deviceSyncHash });

    console.log('Dispatch to start sync from server');
    dispatch(startEventSyncAction(window.userabstraction._id));
};

const syncSalesItemsToServer = async (dispatch: any, setUpSyncFailed: any, callbacks: { addEventForPostSyncTasks: any }) => {
    const salesItemsSaveUrl = DynTableConst.EMPPLAN_HOST + '/salesItems/';
    // find new salesitems
    const newSales = await newSalesItemDb.salesItems.filter(s => !!s.isNewDoc || !!s.syncPending).toArray();
    for (let idx = 0; idx < newSales.length; idx++) {
        const dexieSale = newSales[idx];
        if (dexieSale.isNewDoc) {
            // post
            try {
                if (dexieSale.salesItem?._customReport?.reportResults) {
                    dexieSale.salesItem._customReport.reportResults = await handleReportResultImages(
                        dexieSale.salesItem?._customReport?.reportResults,
                        dispatch,
                    );
                }
                if (dexieSale.salesItem?.crmDexieId) {
                    const linkedDexieCrmItem = await newCRMDb.crmItems.get(dexieSale.salesItem?.crmDexieId);
                    if (
                        linkedDexieCrmItem &&
                        !linkedDexieCrmItem.isNewDoc &&
                        !linkedDexieCrmItem.syncPending &&
                        linkedDexieCrmItem.crmItem._id
                    ) {
                        dexieSale.salesItem.crmLinkId = linkedDexieCrmItem.crmItem._id;
                    } else {
                        throw new Error('linked crm unsynced');
                    }
                }
            } catch (error) {
                dispatch({
                    type: newSyncDataActionTypes.ADD_SYNC_ERROR,
                    payload: { action: 'Failed dexieSale images mutate', error },
                });
                setUpSyncFailed();
                continue;
            }
            // console.log('post dexieSale', dexieSale);
            let response;
            try {
                response = await axios.post(salesItemsSaveUrl, dexieSale.salesItem, {
                    withCredentials: true, // for local dev
                    // for local dev
                    headers: {
                        'Content-Type': 'application/json; charset=UTF-8',
                    },
                });
                // console.log('dexieSale', response);
                if (response?.data && response?.data.eventId && response?.data.productId) {
                    // console.log('Success post');
                    dispatch({
                        type: newSyncDataActionTypes.ADD_SYNC_LOG,
                        payload: { action: 'Uploaded dexieSale', method: 'POST', json: response?.data },
                    });
                    callbacks?.addEventForPostSyncTasks(response?.data.eventId);
                    newSalesItemDb.salesItems.update(dexieSale._id as string, { syncPending: 0, isNewDoc: 0 });
                } else {
                    throw new Error('dexieSale post bad response');
                }
            } catch (error) {
                dispatch({
                    type: newSyncDataActionTypes.ADD_SYNC_ERROR,
                    payload: {
                        action: 'Failed dexieSale',
                        method: 'POST',
                        json: JSON.stringify({ error, response: (error as any)?.response }),
                        error,
                    },
                });
                console.error('Dexie Sale POST', error);
                setUpSyncFailed();
                newSalesItemDb.salesItems.update(dexieSale._id as string, { syncError: 1 });
            }

            continue;
        } else if (dexieSale.syncPending) {
            // put
            try {
                if (dexieSale.salesItem?._customReport?.reportResults) {
                    dexieSale.salesItem._customReport.reportResults = await handleReportResultImages(
                        dexieSale.salesItem?._customReport?.reportResults,
                        dispatch,
                    );
                }
            } catch (error) {
                dispatch({
                    type: newSyncDataActionTypes.ADD_SYNC_ERROR,
                    payload: { action: 'Failed dexieSale images mutate', error },
                });
                setUpSyncFailed();
                continue;
            }
            // console.log('put dexieSale', dexieSale);
            let response;
            try {
                response = await axios.put(salesItemsSaveUrl + dexieSale._id, dexieSale.salesItem, {
                    withCredentials: true, // for local dev
                    // for local dev
                    headers: {
                        'Content-Type': 'application/json; charset=UTF-8',
                    },
                });
                // console.log('dexieSale', response);
                if (response?.data && response?.data.eventId && response?.data.productId) {
                    // console.log('Success put');
                    dispatch({
                        type: newSyncDataActionTypes.ADD_SYNC_LOG,
                        payload: { action: 'Uploaded dexieSale', method: 'PUT', json: response?.data },
                    });
                    callbacks?.addEventForPostSyncTasks(response?.data.eventId);
                    newSalesItemDb.salesItems.update(dexieSale._id as string, { syncPending: 0, isNewDoc: 0 });
                } else {
                    throw new Error('dexieSale put bad response');
                }
            } catch (error) {
                dispatch({
                    type: newSyncDataActionTypes.ADD_SYNC_ERROR,
                    payload: {
                        action: 'Failed dexieSale',
                        method: 'PUT',
                        json: JSON.stringify({ error, response: (error as any)?.response }),
                        error,
                    },
                });
                console.error('Dexie Sale PUT', error);
                setUpSyncFailed();
                newSalesItemDb.salesItems.update(dexieSale._id as string, { syncError: 1 });
            }

            continue;
        }
        console.log('lol dexieSale unhandled case', dexieSale);
    }
};

const syncCrmItemsToServer = async (dispatch: any, setUpSyncFailed: any) => {
    const crmItemsSaveUrl = DynTableConst.EMPPLAN_HOST + '/crm/save';
    const crmItemsUpdateUrl = DynTableConst.EMPPLAN_HOST + '/crm/';
    // find new crmItems
    const newCRMs = await newCRMDb.crmItems.filter(s => !!s.isNewDoc || !!s.syncPending).toArray();
    for (let idx = 0; idx < newCRMs.length; idx++) {
        const dexieCrm = newCRMs[idx];
        if (dexieCrm.isNewDoc) {
            // post
            try {
                if (dexieCrm.crmItem.signId) {
                    if (Array.isArray(dexieCrm.crmItem.signId) && dexieCrm.crmItem.signId.length > 0) {
                        dexieCrm.crmItem.signId = await handleExpenseDocsUploads(dexieCrm.crmItem.signId, dispatch);
                    } else {
                        dexieCrm.crmItem.signId = await handleExpenseDocsUploads(
                            [dexieCrm.crmItem.signId as unknown as string],
                            dispatch,
                        );
                    }
                }
            } catch (error) {
                dispatch({
                    type: newSyncDataActionTypes.ADD_SYNC_ERROR,
                    payload: { action: 'Failed dexieCrm images mutate', error },
                });
                setUpSyncFailed();
                continue;
            }
            // console.log('post dexieCrm', dexieCrm);
            let response;
            try {
                response = await axios.post(crmItemsSaveUrl, dexieCrm.crmItem, {
                    withCredentials: true, // for local dev
                    // for local dev
                    headers: {
                        'Content-Type': 'application/json; charset=UTF-8',
                    },
                });
                // console.log('dexieCrm', response);
                if (response?.data?.success && response?.data?.data?._id) {
                    // console.log('Success post');
                    dispatch({
                        type: newSyncDataActionTypes.ADD_SYNC_LOG,
                        payload: { action: 'Uploaded dexieCrm', method: 'POST', json: response?.data },
                    });
                    newCRMDb.crmItems.update(dexieCrm._id as string, {
                        syncPending: 0,
                        isNewDoc: 0,
                        crmItem: response?.data?.data,
                    });
                } else {
                    throw new Error('dexieCrm post bad response');
                }
            } catch (error) {
                dispatch({
                    type: newSyncDataActionTypes.ADD_SYNC_ERROR,
                    payload: {
                        action: 'Failed dexieCrm',
                        method: 'POST',
                        json: JSON.stringify({ error, response: (error as any)?.response }),
                        error,
                    },
                });
                setUpSyncFailed();
                newCRMDb.crmItems.update(dexieCrm._id as string, { syncError: 1 });
            }

            continue;
        } else if (dexieCrm.syncPending) {
            // put
            try {
                if (dexieCrm.crmItem.signId) {
                    if (Array.isArray(dexieCrm.crmItem.signId) && dexieCrm.crmItem.signId.length > 0) {
                        dexieCrm.crmItem.signId = await handleExpenseDocsUploads(dexieCrm.crmItem.signId, dispatch);
                    } else {
                        dexieCrm.crmItem.signId = await handleExpenseDocsUploads(
                            [dexieCrm.crmItem.signId as unknown as string],
                            dispatch,
                        );
                    }
                }
            } catch (error) {
                dispatch({
                    type: newSyncDataActionTypes.ADD_SYNC_ERROR,
                    payload: { action: 'Failed dexieCrm images mutate', error },
                });
                setUpSyncFailed();
                continue;
            }
            // console.log('put dexieCrm', dexieCrm);
            let response;
            try {
                response = await axios.put(crmItemsUpdateUrl + dexieCrm._id, dexieCrm.crmItem, {
                    withCredentials: true, // for local dev
                    // for local dev
                    headers: {
                        'Content-Type': 'application/json; charset=UTF-8',
                    },
                });
                // console.log('dexieCrm', response);
                if (response?.data?.success && response?.data?.data?._id) {
                    // console.log('Success put');
                    dispatch({
                        type: newSyncDataActionTypes.ADD_SYNC_LOG,
                        payload: { action: 'Uploaded dexieCrm', method: 'PUT', json: response?.data },
                    });
                    // callbacks?.addEventForPostSyncTasks(response?.data.eventId);
                    newCRMDb.crmItems.update(dexieCrm._id as string, { syncPending: 0, isNewDoc: 0 });
                } else {
                    throw new Error('dexieCrm put bad response');
                }
            } catch (error) {
                dispatch({
                    type: newSyncDataActionTypes.ADD_SYNC_ERROR,
                    payload: {
                        action: 'Failed dexieCrm',
                        method: 'PUT',
                        json: JSON.stringify({ error, response: (error as any)?.response }),
                        error,
                    },
                });
                setUpSyncFailed();
                newCRMDb.crmItems.update(dexieCrm._id as string, { syncError: 1 });
            }

            continue;
        }
        console.log('lol dexieCrm unhandled case', dexieCrm);
    }
};

const syncExpensesToServer = async (dispatch: any, setUpSyncFailed: any, callbacks: { addEventForPostSyncTasks: any }) => {
    const expenseSaveUrl = DynTableConst.EMPPLAN_HOST + '/expense/';
    // find new expense
    const filteredDexieExpenses = await newExpenseDb.expenses.filter(s => !!s.isNewDoc || !!s.syncPending).toArray();
    for (let idx = 0; idx < filteredDexieExpenses.length; idx++) {
        const dexieExpense = filteredDexieExpenses[idx];
        if (dexieExpense.isNewDoc) {
            // post
            try {
                if (Array.isArray(dexieExpense.expense.expenseDocs)) {
                    dexieExpense.expense.expenseDocs = await handleExpenseDocsUploads(
                        dexieExpense.expense.expenseDocs,
                        dispatch,
                    );
                }
                if (dexieExpense.expense?._customReport?.reportResults) {
                    dexieExpense.expense._customReport.reportResults = await handleReportResultImages(
                        dexieExpense.expense?._customReport?.reportResults,
                        dispatch,
                    );
                }
            } catch (error) {
                dispatch({
                    type: newSyncDataActionTypes.ADD_SYNC_ERROR,
                    payload: { action: 'Failed dexieExpense images mutate', error },
                });
                setUpSyncFailed();
                continue;
            }
            // console.log('post dexieExpense', dexieExpense);
            let response;
            try {
                response = await axios.post(expenseSaveUrl, dexieExpense.expense, {
                    withCredentials: true, // for local dev
                    // for local dev
                    headers: {
                        'Content-Type': 'application/json; charset=UTF-8',
                    },
                });
                // console.log('dexieExpense', response);
                if (response?.data && response?.data.qty && response?.data.eventId && response?.data.productId) {
                    // console.log('Success post');
                    dispatch({
                        type: newSyncDataActionTypes.ADD_SYNC_LOG,
                        payload: { action: 'Uploaded dexieExpense', method: 'POST', json: response?.data },
                    });
                    callbacks?.addEventForPostSyncTasks(response?.data.eventId);
                    newExpenseDb.expenses.update(dexieExpense._id as string, { syncPending: 0, isNewDoc: 0 });
                } else {
                    throw new Error('dexieExpense post bad response');
                }
            } catch (error) {
                dispatch({
                    type: newSyncDataActionTypes.ADD_SYNC_ERROR,
                    payload: {
                        action: 'Failed dexieExpense',
                        method: 'POST',
                        json: JSON.stringify({ error, response: (error as any)?.response }),
                        error,
                    },
                });
                setUpSyncFailed();
                newExpenseDb.expenses.update(dexieExpense._id as string, { syncError: 1 });
            }

            continue;
        } else if (dexieExpense.syncPending) {
            // put
            try {
                if (Array.isArray(dexieExpense.expense.expenseDocs)) {
                    dexieExpense.expense.expenseDocs = await handleExpenseDocsUploads(
                        dexieExpense.expense.expenseDocs,
                        dispatch,
                    );
                }
                if (dexieExpense.expense?._customReport?.reportResults) {
                    dexieExpense.expense._customReport.reportResults = await handleReportResultImages(
                        dexieExpense.expense?._customReport?.reportResults,
                        dispatch,
                    );
                }
            } catch (error) {
                dispatch({
                    type: newSyncDataActionTypes.ADD_SYNC_ERROR,
                    payload: { action: 'Failed dexieExpense images mutate', error },
                });
                setUpSyncFailed();
                continue;
            }
            // console.log('put dexieExpense', dexieExpense);
            let response;
            try {
                response = await axios.put(expenseSaveUrl + dexieExpense._id, dexieExpense.expense, {
                    withCredentials: true, // for local dev
                    // for local dev
                    headers: {
                        'Content-Type': 'application/json; charset=UTF-8',
                    },
                });
                // console.log('dexieExpense', response);
                if (response?.data && response?.data.qty && response?.data.eventId && response?.data.productId) {
                    // console.log('Success put');
                    dispatch({
                        type: newSyncDataActionTypes.ADD_SYNC_LOG,
                        payload: { action: 'Uploaded dexieExpense', method: 'PUT', json: response?.data },
                    });
                    callbacks?.addEventForPostSyncTasks(response?.data.eventId);
                    newExpenseDb.expenses.update(dexieExpense._id as string, { syncPending: 0, isNewDoc: 0 });
                } else {
                    throw new Error('dexieExpense put bad response');
                }
            } catch (error) {
                dispatch({
                    type: newSyncDataActionTypes.ADD_SYNC_ERROR,
                    payload: {
                        action: 'Failed dexieExpense',
                        method: 'PUT',
                        json: JSON.stringify({ error, response: (error as any)?.response }),
                        error,
                    },
                });
                setUpSyncFailed();
                newExpenseDb.expenses.update(dexieExpense._id as string, { syncError: 1 });
            }

            continue;
        }
        console.log('lol dexieExpense unhandled case', dexieExpense);
    }
};

const handleExpenseDocsUploads = async (expenseDocs: Array<string>, dispatch: any) => {
    for (let idx = 0; idx < expenseDocs.length; idx++) {
        const docId = expenseDocs[idx];
        if (typeof docId === 'string' && docId.startsWith('dexie.')) {
            console.log('There is supposidely an upload in dexie', docId);
            const newImageId = await handleImageUpload(docId, dispatch);
            expenseDocs[idx] = newImageId;
        }
    }
    return expenseDocs;
};

const syncCustomReportResultsToServer = async (
    dispatch: any,
    setUpSyncFailed: any,
    callbacks: { addEventForPostSyncTasks: any },
) => {
    const customReportResultsSaveUrl = DynTableConst.EMPPLAN_HOST + '/customreportresult/';
    const customReportResultDexieDocs = await newCustomReportResultDb.customReportResults
        .filter(s => !!s.isNewDoc || !!s.syncPending)
        .toArray();

    for (let idx = 0; idx < customReportResultDexieDocs.length; idx++) {
        const dexieCRR = customReportResultDexieDocs[idx];

        // Check for initial customreportresult keys
        let finalReportResultsKeys = 0;
        const initialReportResultsKeys = Object.keys(dexieCRR?.customReportResult?.reportResults || {}).filter(
            k => k !== '__emp',
        ).length;

        if (true) {
            // post
            try {
                if (dexieCRR.customReportResult?.reportResults) {
                    dexieCRR.customReportResult.reportResults = await handleReportResultImages(
                        dexieCRR.customReportResult?.reportResults,
                        dispatch,
                    );
                    dexieCRR.customReportResult.reportResults = parseSavedReportResults(
                        dexieCRR.customReportResult.reportResults,
                    );
                }
            } catch (error) {
                console.error('Failed dexieCRR reportresults/images mutate', error);
                dispatch({
                    type: newSyncDataActionTypes.ADD_SYNC_ERROR,
                    payload: { action: 'Failed dexieCRR reportresults/images mutate', error },
                });
                setUpSyncFailed();
                continue;
            }

            // compare with final customreportresult keys
            try {
                finalReportResultsKeys = Object.keys(dexieCRR?.customReportResult?.reportResults || {}).filter(
                    k => k !== '__emp',
                ).length;

                if (initialReportResultsKeys !== finalReportResultsKeys) {
                    throw new Error('unequal keys');
                }
            } catch (error) {
                console.error('Failed dexieCRR - bad keys', error);
                dispatch({
                    type: newSyncDataActionTypes.ADD_SYNC_ERROR,
                    payload: { action: 'Failed dexieCRR - bad keys' },
                    initialReportResultsKeys,
                    finalReportResultsKeys,
                    error,
                });
                setUpSyncFailed();
                newCustomReportResultDb.customReportResults.update(dexieCRR._id as string, { syncError: 1 });
                continue;
            }

            // console.log('post dexieCRR', dexieCRR);
            let response;
            try {
                response = await axios.post(customReportResultsSaveUrl + '?pwa=true', dexieCRR.customReportResult, {
                    withCredentials: true, // for local dev
                    // for local dev
                    headers: {
                        'Content-Type': 'application/json; charset=UTF-8',
                    },
                });
                // console.log('dexieCRR', response);
                if (response?.data?.reportResults && response.data.user && response.data._customReport) {
                    // console.log('Success post');
                    dispatch({
                        type: newSyncDataActionTypes.ADD_SYNC_LOG,
                        payload: { action: 'Uploaded dexieCRR', method: 'POST', json: response?.data },
                    });
                    newCustomReportResultDb.customReportResults.update(dexieCRR._id as string, {
                        syncPending: 0,
                        isNewDoc: 0,
                    });
                } else {
                    throw new Error('dexieCRR post bad response');
                }
            } catch (error) {
                if ((error as any)?.response?.data?.errors === 'cannot modify report') {
                    dispatch({
                        type: newSyncDataActionTypes.ADD_SYNC_LOG,
                        payload: {
                            action: 'Uploaded dexieCRR - cannot modify',
                            method: 'POST',
                            json: JSON.stringify({ error, response: (error as any)?.response }),
                        },
                    });
                    newCustomReportResultDb.customReportResults.update(dexieCRR._id as string, {
                        syncPending: 0,
                        isNewDoc: 0,
                    });
                } else {
                    dispatch({
                        type: newSyncDataActionTypes.ADD_SYNC_ERROR,
                        payload: {
                            action: 'Failed dexieCRR',
                            method: 'POST',
                            json: JSON.stringify({ error, response: (error as any)?.response }),
                            error,
                        },
                    });
                    setUpSyncFailed();
                    newCustomReportResultDb.customReportResults.update(dexieCRR._id as string, { syncError: 1 });
                }
            }

            continue;
        } else if (dexieCRR.syncPending) {
            // put
            try {
                if (dexieCRR.customReportResult?.reportResults) {
                    dexieCRR.customReportResult.reportResults = await handleReportResultImages(
                        dexieCRR.customReportResult.reportResults,
                        dispatch,
                    );
                    dexieCRR.customReportResult.reportResults = parseSavedReportResults(
                        dexieCRR.customReportResult.reportResults,
                    );
                }
            } catch (error) {
                dispatch({
                    type: newSyncDataActionTypes.ADD_SYNC_ERROR,
                    payload: { action: 'Failed dexieCRR reportresults/images mutate', error },
                });
                setUpSyncFailed();
                continue;
            }
            // console.log('put dexieCRR', dexieCRR);

            let response;
            try {
                response = await axios.put(customReportResultsSaveUrl + dexieCRR._id, dexieCRR.customReportResult, {
                    withCredentials: true, // for local dev
                    // for local dev
                    headers: {
                        'Content-Type': 'application/json; charset=UTF-8',
                    },
                });
                // console.log('dexieCRR', response);
                if (response?.data?.reportResults && response.data.user && response.data._customReport) {
                    // console.log('Success put');
                    dispatch({
                        type: newSyncDataActionTypes.ADD_SYNC_LOG,
                        payload: { action: 'Uploaded dexieCRR', method: 'PUT', json: response?.data },
                    });
                    newCustomReportResultDb.customReportResults.update(dexieCRR._id as string, {
                        syncPending: 0,
                        isNewDoc: 0,
                    });
                } else {
                    throw new Error('dexieCRR put bad response');
                }
            } catch (error) {
                dispatch({
                    type: newSyncDataActionTypes.ADD_SYNC_ERROR,
                    payload: { action: 'Failed dexieCRR', method: 'PUT', json: response, error },
                });
                setUpSyncFailed();
                newCustomReportResultDb.customReportResults.update(dexieCRR._id as string, { syncError: 1 });
            }

            continue;
        }
        console.log('lol dexieCRR unhandled case', dexieCRR);
    }
};

const handleReportResultImages = async (reportResults: any, dispatch: any) => {
    if (reportResults) {
        const fields = Object.keys(reportResults);
        for await (const fieldId of fields) {
            const fieldValue = reportResults[fieldId];
            // console.log({ fieldValue });

            if (typeof fieldValue?.value === 'string' && fieldValue.value.startsWith('dexie.')) {
                // console.log('There is supposidely an upload in dexie', fieldValue?.value);
                const newImageId = await handleImageUpload(fieldValue.value, dispatch);
                reportResults[fieldId].value = newImageId;
            }
        }
    }
    return reportResults;
};

const handleImageUpload = async (dexieImageId: string, dispatch: any) => {
    // dexieImageId: dexie.7a5d8a76d578ad587ad
    const dexieFileId = dexieImageId.split('.')[1];
    const dexieFile = await newFileSystemDb.fileSystems.get(dexieFileId);
    if (!(dexieFile && dexieFile.fileSystemPayload)) return '';
    if (dexieFile && dexieFile.syncPending === 0 && dexieFile.serverFileId) return dexieFile.serverFileId;
    let serverFileId;
    try {
        serverFileId = await uploadFile(
            dexieFile.fileSystemPayload,
            DynTableConst.EMPPLAN_HOST + dexieFile.context.remotePath,
        );
    } catch (error) {
        dispatch({
            type: newSyncDataActionTypes.ADD_SYNC_ERROR,
            payload: { action: 'Failed image upload', error, dexieImageId },
        });
        throw new Error('Failed to upload image');
    }
    await newFileSystemDb.fileSystems.update(dexieFileId, { serverFileId, syncPending: 0, isNewDoc: 0 });
    dispatch({ type: newSyncDataActionTypes.ADD_SYNC_LOG, payload: { action: 'Uploaded file to server', serverFileId } });
    return serverFileId;
};

const cleanNewFileSystem = async (dispatch: any) => {
    const deleteCount = await newFileSystemDb.fileSystems.filter(f => !!f).delete();
    console.log('Deleted ' + deleteCount + ' filesystem objects');
    dispatch({ type: newSyncDataActionTypes.ADD_SYNC_LOG, payload: { action: 'Cleared filesystem', deleteCount } });
};

const cleanEventActions = async (dispatch: any) => {
    const deleteCount = await newEventActionsDb.eventActions.filter(f => f.synced === true).delete();
    dispatch({ type: newSyncDataActionTypes.ADD_SYNC_LOG, payload: { action: 'Cleared eventActions', deleteCount } });
};

const cleanCRMActions = async (dispatch: any) => {
    const deleteCount = await newCRMActionsDb.crmActions.filter(f => f.synced === true).delete();
    dispatch({ type: newSyncDataActionTypes.ADD_SYNC_LOG, payload: { action: 'Cleared crm actions', deleteCount } });
};

const syncCRMActions = async (dispatch: any, setUpSyncFailed: any, callbacks: { addEventForPostSyncTasks: any }) => {
    const crmActionsToPost = await newCRMActionsDb.crmActions
        .orderBy('lastChangeDate')
        .filter(s => s.synced === false)
        .toArray();

    const addSyncError = (error: any, method: 'PUT' | 'POST', message: string) => {
        console.error(error);
        dispatch({
            type: newSyncDataActionTypes.ADD_SYNC_ERROR,
            payload: {
                action: message,
                method,
                json: JSON.stringify({ error, response: (error as any)?.response }),
            },
        });
        setUpSyncFailed();
    };

    for (const crmAction of crmActionsToPost) {
        if (crmAction.action.type === 'Ring') {
            try {
                const { payload } = crmAction.action;
                payload.ringTime = crmAction.timeOfaction;
                const response = await axios.post(
                    `/crm/ring?crmId=${payload.crmId}&projectId=${payload.projectId}`,
                    payload.payload ?? {},
                );
                if (response.data.success && response.data.data?._id && response.data.data.ringDetails) {
                    await newCRMActionsDb.crmActions.update(crmAction._id, {
                        synced: true,
                        lastChangeDate: new Date(),
                    });
                    dispatch({
                        type: newSyncDataActionTypes.ADD_SYNC_LOG,
                        payload: { action: 'Synced crm ring' },
                    });
                } else {
                    addSyncError({ response, message: 'Failed crm ring' }, 'POST', 'Failed crm ring');
                }
            } catch (error) {
                addSyncError(error, 'POST', 'Failed crm ring');
            }
        }

        if (crmAction.action.type === 'Status') {
            try {
                const { payload } = crmAction.action;
                const response = await axios.put(`crm/${payload.crmId}`, payload.payload ?? {});
                if (response.data.success && response.data.data?._id) {
                    await newCRMActionsDb.crmActions.update(crmAction._id, {
                        synced: true,
                        lastChangeDate: new Date(),
                    });
                    dispatch({
                        type: newSyncDataActionTypes.ADD_SYNC_LOG,
                        payload: { action: 'Synced crm status' },
                    });
                } else {
                    addSyncError({ response, message: 'Failed crm status' }, 'POST', 'Failed crm status');
                }
            } catch (error) {
                addSyncError(error, 'POST', 'Failed crm status');
            }
        }

        if (crmAction.action.type === 'Update') {
            try {
                const { payload, _id } = crmAction.action;
                const response = await axios.put(`crm/${_id}`, payload ?? {});
                if (response.data.success && response.data.data?._id) {
                    await newCRMActionsDb.crmActions.update(crmAction._id, {
                        synced: true,
                        lastChangeDate: new Date(),
                    });
                    dispatch({
                        type: newSyncDataActionTypes.ADD_SYNC_LOG,
                        payload: { action: 'Synced crm update' },
                    });
                } else {
                    addSyncError({ response, message: 'Failed crm update' }, 'POST', 'Failed crm update');
                }
            } catch (error) {
                addSyncError(error, 'POST', 'Failed crm update');
            }
        }

        if (crmAction.action.type === 'CustomStatusUpdate') {
            try {
                const { payload, _id } = crmAction.action;
                const response = await axios.put(`crm/${_id}`, payload ?? {});
                if (response.data.success && response.data.data?._id) {
                    await newCRMActionsDb.crmActions.update(crmAction._id, {
                        synced: true,
                        lastChangeDate: new Date(),
                    });
                    dispatch({
                        type: newSyncDataActionTypes.ADD_SYNC_LOG,
                        payload: { action: 'Synced crm CustomStatusUpdate' },
                    });
                } else {
                    addSyncError(
                        { response, message: 'Failed crm CustomStatusUpdate' },
                        'POST',
                        'Failed crm CustomStatusUpdate',
                    );
                }
            } catch (error) {
                addSyncError(error, 'POST', 'Failed crm CustomStatusUpdate');
            }
        }

        if (crmAction.action.type === 'CRMSales') {
            try {
                const eventId = crmAction._id;
                const salesItem: SalesItem[] = crmAction.action.payload;
                const crmId = crmAction.action._id;
                const crmWrapper = await newCRMDb.crmItems.get(crmId);
                const crmData = crmWrapper?.crmItem;
                const eventIds = crmData?.eventId as string[];
                const validEventIds = (Array.isArray(eventIds) ? eventIds : []).filter(id => typeof id === 'string' && id.length > 0);
                const eventDexie = await newEventDb.events.where("_id").anyOf(validEventIds).toArray();
                const eventData = eventDexie.map(e => ({ ...e.event }));
                const newCrmData = {
                    ...crmData,
                    eventId: eventData || [],
                }
                const payload = {
                    crmItem: newCrmData,
                    project_id: crmAction.action.projectId,
                    point_of_sale: generatePOS(newCrmData as CRMData),
                    uniqueCheckFailed: true,
                    crmId: newCrmData?._id,
                    type: 'newEventCheck',
                    placeId: newCrmData?.address?.placeId,
                };
                const res = await axios.post(`/crm/checkin`, payload, {
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json; charset=UTF-8',
                    },
                });
                const results = res.data;
                let errors = false;
                if (results.event) {
                    await newCRMActionsDb.crmActions.update(eventId, {
                        synced: true,
                        lastChangeDate: new Date(),
                    });
                    await newEventDb.events.where("_id").equals(eventId).delete();
                    const newEventId = results.event._id;
                    const newSalesItem = salesItem.map(sales => ({ ...sales, eventId: newEventId }));
                    for (const sale of newSalesItem) {
                        try {
                            if (sale?._customReport?.reportResults) {
                                sale._customReport.reportResults = await handleReportResultImages(
                                    sale?._customReport?.reportResults,
                                    dispatch,
                                );
                            }
                            if (crmId) {
                                const linkedDexieCrmItem = await newCRMDb.crmItems.get(crmId);
                                if (
                                    linkedDexieCrmItem &&
                                    !linkedDexieCrmItem.isNewDoc &&
                                    !linkedDexieCrmItem.syncPending &&
                                    linkedDexieCrmItem.crmItem._id
                                ) {
                                    sale.crmLinkId = linkedDexieCrmItem.crmItem._id;
                                } else {
                                    errors = true;
                                    throw new Error('linked crm unsynced');
                                }
                            }
                        } catch (error) {
                            errors = true;
                            dispatch({
                                type: newSyncDataActionTypes.ADD_SYNC_ERROR,
                                payload: { action: 'Failed dexieSale images mutate', error },
                            });
                            setUpSyncFailed();
                            continue;
                        }
                        let response;
                        try {
                            response = await axios.post(DynTableConst.EMPPLAN_HOST + '/salesItems/', sale, {
                                withCredentials: true,
                                headers: {
                                    'Content-Type': 'application/json; charset=UTF-8',
                                },
                            });
                            if (response?.data && response?.data.eventId && response?.data.productId) {
                                if (sale._id) {
                                    await newSalesItemDb.salesItems.where("_id").equals(sale._id).delete();
                                }
                                dispatch({
                                    type: newSyncDataActionTypes.ADD_SYNC_LOG,
                                    payload: { action: 'Uploaded dexieSale', method: 'POST', json: response?.data },
                                });
                                callbacks?.addEventForPostSyncTasks(response?.data.eventId);
                            } else {
                                errors = true;
                                throw new Error('dexieSale post bad response');
                            }
                        } catch (error) {
                            errors = true;
                            await newSalesItemDb.salesItems.update(eventId, { syncError: 1, salesItem: newSalesItem });
                            dispatch({
                                type: newSyncDataActionTypes.ADD_SYNC_ERROR,
                                payload: {
                                    action: 'Failed dexieSale',
                                    method: 'POST',
                                    json: JSON.stringify({ error, response: (error as any)?.response }),
                                    error,
                                },
                            });
                            console.error('Dexie Sale POST', error);
                            setUpSyncFailed();
                            continue;
                        }
                    }
                    if (!errors && newEventId) {
                        const url = `${DynTableConst.EMPPLAN_HOST}/salesItems/report/upload/${newEventId}`;
                        const res = await axios.post(url, { SignedForm: true });
                        if (res.data?._id) {
                            dispatch({
                                type: newSyncDataActionTypes.ADD_SYNC_LOG,
                                payload: { action: 'Uploaded event SubmitReport' },
                            });
                            console.log(res.data, "Event Report Submitted");
                        } else {
                            console.error(res.data, "Failed Event Report Submit");
                        }
                    }
                } else {
                    addSyncError('Failed Event Generation', 'POST', 'Failed crm eventGeneration');
                }
            } catch (error) {
                addSyncError(error, 'POST', 'Failed crm eventGeneration');
            }
        }
    }
};

const syncEventActions = async (dispatch: any, setUpSyncFailed: any, callbacks: { addEventForPostSyncTasks: any }) => {
    const eventActionsToPost = await newEventActionsDb.eventActions
        .orderBy('lastChangeDate')
        .filter(s => s.synced === false)
        .toArray();

    const submitReportUrl = DynTableConst.EMPPLAN_HOST + '/salesItems/report/upload/';
    const dateChangeUrl = DynTableConst.EMPPLAN_HOST + '/event/group/';
    const checkInCheckOutUrl = DynTableConst.EMPPLAN_HOST + '/event/';

    const addSyncError = (error: any, method: 'PUT' | 'POST', message: string) => {
        console.error(error);
        dispatch({
            type: newSyncDataActionTypes.ADD_SYNC_ERROR,
            payload: {
                action: message,
                method,
                json: JSON.stringify({ error, response: (error as any)?.response }),
            },
        });
        setUpSyncFailed();
    };

    for (const mainAction of eventActionsToPost) {
        const { _id, type, payload } = mainAction.action;

        if (type === 'SubmitReport') {
            try {
                const res = await axios.post(submitReportUrl + _id, { SignedForm: true });
                if (res.data?._id) {
                    await newEventActionsDb.eventActions.update(mainAction._id, {
                        synced: true,
                        lastChangeDate: new Date(),
                    });
                    callbacks?.addEventForPostSyncTasks(_id);
                    dispatch({
                        type: newSyncDataActionTypes.ADD_SYNC_LOG,
                        payload: { action: 'Uploaded event SubmitReport' },
                    });
                } else {
                    if (
                        res.data?.message?.eventState &&
                        ['SalesReportUploaded', 'SalesReportAccepted', 'Invoiced', 'Paid'].includes(
                            res.data?.message?.eventState,
                        )
                    ) {
                        await newEventActionsDb.eventActions.update(mainAction._id, {
                            synced: true,
                            lastChangeDate: new Date(),
                        });
                        console.error(
                            'Skipped event SubmitReport - already accepted',
                            mainAction.action?._id,
                            window.location?.host,
                            window.userabstraction?._id,
                        );
                        callbacks?.addEventForPostSyncTasks(_id);
                        dispatch({
                            type: newSyncDataActionTypes.ADD_SYNC_LOG,
                            payload: { action: 'Skipped event SubmitReport - already accepted' },
                        });
                    } else {
                        addSyncError({ response: 'submit report failed newSync file' }, 'POST', 'Failed SubmitReport');
                    }
                }
            } catch (error) {
                addSyncError(error, 'POST', 'Failed SubmitReport');
            }
        } else if (type === 'CheckIn' || type === 'CheckOut' || type === 'AcceptReport') {
            try {
                const offlinePayload = payload;
                const timeOfCheckIn = JSON.parse(mainAction.timeOfaction);
                const putUrl =
                    checkInCheckOutUrl +
                    _id +
                    `/flow?device=${navigator.userAgent}&isMobile=${offlinePayload.isMobile ? 'true' : 'false'}` +
                    `&latitude=${offlinePayload.latitude}&longitude=${offlinePayload.longitude}&pwa=true&pwaDate=${timeOfCheckIn}`;

                const res = await axios.put(putUrl, offlinePayload.event);
                if (res.data._id) {
                    await newEventActionsDb.eventActions.update(mainAction._id, {
                        synced: true,
                        lastChangeDate: new Date(),
                    });
                    callbacks?.addEventForPostSyncTasks(_id);
                    dispatch({ type: newSyncDataActionTypes.ADD_SYNC_LOG, payload: { action: `Uploaded event ${type}` } });
                } else {
                    addSyncError({ response: `${type} Failed, NewSync File` }, 'PUT', `Failed ${type}`);
                }
            } catch (error) {
                if ((error as any)?.message === 'Request failed with status code 404') {
                    // event maybe deleted from server
                    await checkAndDeleteEventActionsOfDeletedEvents(dispatch, _id, setUpSyncFailed);
                } else {
                    addSyncError(error, 'PUT', `Failed ${type}`);
                }
            }
        } else if (type === 'DateChange') {
            try {
                const timeOfDateChange = JSON.parse(mainAction.timeOfaction);
                const putUrl = dateChangeUrl + _id + `?pwa=true&pwaDate=${timeOfDateChange}`;
                const res = await axios.put(putUrl, JSON.parse(payload));
                if (res.data.success) {
                    await newEventActionsDb.eventActions.update(mainAction._id, {
                        synced: true,
                        lastChangeDate: new Date(),
                    });
                    callbacks?.addEventForPostSyncTasks(_id);
                    dispatch({
                        type: newSyncDataActionTypes.ADD_SYNC_LOG,
                        payload: { action: 'Uploaded event DateChange' },
                    });
                } else {
                    addSyncError({ response: 'Date Change Failed, NewSync File' }, 'PUT', 'Failed DateChange');
                }
            } catch (error) {
                addSyncError(error, 'PUT', 'Failed DateChange');
            }
        }
    }
};

const checkAndDeleteEventActionsOfDeletedEvents = async (dispatch: any, eventId: string, setUpSyncFailed: any) => {
    try {
        // check if event exist on the server
        const eventsRes = await getEventsFetch({ limit: 1, skip: 0 }, window.userabstraction._id as string, {
            eventId: eventId,
        });
        // console.log('Checking event on server', eventsRes);

        if (eventsRes.success && eventsRes.count === 0 && Array.isArray(eventsRes.data) && eventsRes.data.length === 0) {
            // if not then delete event related actions from dexie
            const deleteCount = await newEventActionsDb.eventActions.filter(ea => ea.action._id === eventId).delete();
            console.error('deleted event actions: ', deleteCount);
        } else {
            dispatch({
                type: newSyncDataActionTypes.ADD_SYNC_ERROR,
                payload: {
                    action: 'Event check - CheckIn/CheckOut',
                    method: 'PUT',
                    error: 'Event still exists on server/malformed response',
                    json: eventsRes,
                },
            });
            setUpSyncFailed();
        }
    } catch (error) {
        console.error('error checking if event exist', error);
        dispatch({
            type: newSyncDataActionTypes.ADD_SYNC_ERROR,
            payload: {
                action: 'Failed event check - CheckIn/CheckOut',
                method: 'PUT',
                message: 'error checking if event still exist on server',
                json: error,
            },
        });
        setUpSyncFailed();
    }
};

const runPostSyncUpEventTasks = async (eventIds: Array<string>) => {
    for (let idx = 0; idx < eventIds.length; idx++) {
        const eventId = eventIds[idx];
        try {
            await axios.post(
                `/targetdefinitionsprofile/calculate/${eventId}`,
                {},
                {
                    withCredentials: true, // for local dev
                    // for local dev
                    headers: {
                        'Content-Type': 'application/json; charset=UTF-8',
                    },
                },
            );
        } catch (error) {
            console.error(
                'failed to call targets update endpoint for event',
                eventId,
                JSON.stringify({ error, response: (error as any)?.response }),
            );
        }
    }
};

export default newSyncDataActionTypes;
