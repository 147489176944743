import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { ReactNode, useState } from 'react';
import { t } from 'i18next';
export const useConfirm = () => {
    const [promise, setPromise] = useState<any>(null);
    const [title, setTitle] = useState<ReactNode>();
    const [message, setMessage] = useState<ReactNode>();
    const [actionMsg, setActionMsg] = useState<{ start?: string; end?: string }>({});

    const confirm = (title: ReactNode, message: ReactNode, actionMsg?: { start?: string; end?: string }) =>
        new Promise<boolean>((resolve, reject) => {
            setTitle(title);
            setMessage(message);
            setActionMsg(actionMsg || {});
            setPromise({ resolve });
        });

    const handleClose = () => {
        setPromise(null);
    };

    const handleConfirm = () => {
        promise?.resolve(true);
        handleClose();
    };

    const handleCancel = () => {
        promise?.resolve(false);
        handleClose();
    };
    interface ConfirmationDialogProps {
        cancelOnly?: Boolean;
    }
    // You could replace the Dialog with your library's version
    const ConfirmationDialog = ({ cancelOnly }: ConfirmationDialogProps) => (
        <Dialog open={promise !== null} fullWidth>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <DialogContentText>{message}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button style={{ visibility: cancelOnly ? 'hidden' : 'visible' }} onClick={handleConfirm}>
                    {actionMsg.start ?? t('##Yes')}
                </Button>
                <Button onClick={handleCancel}>{actionMsg.end ?? t('##Cancel')}</Button>
            </DialogActions>
        </Dialog>
    );
    return [ConfirmationDialog, confirm] as const;
};
