import API from '../../config/api';
import { AutosuggestConfig, EmplanFilterField, selectConfig } from '../FilterLine/filterLine';
import { generateRandomKey } from '../CustomReportDependentFields/keygen';
import { t } from 'i18next';
export const customFieldsFilterConfig = (url: string) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    let filterConfig: EmplanFilterField[] = [];
    try {
        return API.get(url)
            .then(response => response.json())
            .then(data => {
                const schemaPaths = data.schemaPaths;
                if(schemaPaths && Array.isArray(schemaPaths)){
                    schemaPaths.forEach(
                        (scheme: {
                            label: any;
                            name: any;
                            items: {
                                type: string;
                                options: any[];
                            }[];
                        }) => {
                            // removing the backend schema for country
                            if (scheme.label !== '##address.country') {
                                let singleConfig: EmplanFilterField | AutosuggestConfig | selectConfig = {
                                    _id: generateRandomKey(),
                                    label: t(scheme.label),
                                    name: scheme.name,
                                    type: scheme?.items[0]?.type === 'select' ? ('select' as 'select') : ('text' as 'text'),
                                    operators: [
                                        {
                                            _id: generateRandomKey(),
                                            name: t('##IsEqualTo'),
                                            value: '=',
                                            mongoOperator: '$eq',
                                        },
                                        {
                                            _id: generateRandomKey(),
                                            name: t('##Contains'),
                                            value: 'like',
                                            mongoOperator: '$like',
                                        },
                                        {
                                            _id: generateRandomKey(),
                                            name: t('##DoesNotContain'),
                                            value: 'notlike',
                                            mongoOperator: '$notlike',
                                        },
                                    ],
                                    options: () => {
                                        if (scheme?.items[0]?.type === 'select') {
                                            let options: any[] = [];
                                            scheme?.items[0].options.forEach(option => {
                                                let opt = {
                                                    _id: generateRandomKey(),
                                                    name: t(option.label),
                                                    value: option.value,
                                                };
                                                options.push(opt);
                                            });
                                            return options;
                                        } else {
                                            return [];
                                        }
                                    },
                                    isHidden: () => {
                                        return false;
                                    },
                                    callback: () => {
                                        return true;
                                    },
                                };
                                filterConfig.push(singleConfig);
                            }
                        },
                    );
                }
                
                return filterConfig;
            });
    } catch (error) {
        console.error('Error fetching data', error);
    }
};

export const eventDataFieldsFilterConfig = (url: string) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    let filterConfig: EmplanFilterField[] = [];
    try {
        return API.get(url)
            .then(response => response.json())
            .then(data => {
                const schemaPaths = data.fields;
                console.log('schemaPaths',schemaPaths)
                schemaPaths.forEach(
                    (scheme: any) => {
                        // removing the backend schema for country
                        
                            let singleConfig: EmplanFilterField | AutosuggestConfig | selectConfig = {
                                _id: generateRandomKey(),
                                label: t(scheme.name),
                                name: `cfdata_${scheme._id}`,
                                type: 'select' as 'select',
                                operators: [
                                    {
                                        _id: generateRandomKey(),
                                        name: t('##IsEqualTo'),
                                        value: '=',
                                        mongoOperator: '$eq',
                                    },
                                ],
                                options: () => {
                                   
                                        let options: any[] = [];
                                        scheme?.fieldObject?.options.forEach((option: any) => {
                                            let opt = {
                                                _id: generateRandomKey(),
                                                name: option,
                                                value: option,
                                            };
                                            options.push(opt);
                                        });
                                        return options;
                                    
                                },
                                isHidden: () => {
                                    return false;
                                },
                                callback: () => {
                                    return true;
                                },
                            };
                            filterConfig.push(singleConfig);
                        
                    },
                );
                return filterConfig;
            });
    } catch (error) {
        console.error('Error fetching data', error);
    }
};
