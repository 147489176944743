import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import API from '../../config/api';
import { useTranslation } from 'react-i18next';
import {IGenericFields} from "../../components-ui/table/hooks/build";

// copied from angular code file: packages/custom/helperdirectivesearchboxdropdown/public/controllers/searchBoxDynamicCtrl.js
// modified a bit here and there but probably needs more work as and when filters required
const userUrlGenerator = (searchText?: string, options?: any) => {
    let filterClient = '';
    // if (typeof $scope.filterObj !== 'undefined') {
    //     if (typeof $scope.filterObj._id !== 'undefined') {
    //         filterClient = '&usertype=' + $scope.filterObj._id;
    //         objectIndexForblank += $scope.filterObj._id;
    //     } else if (typeof $scope.filterObj.usertype !== 'undefined') {
    //         filterClient = '&usertype=' + $scope.filterObj.usertype;
    //         objectIndexForblank += $scope.filterObj.usertype;
    //     } else {
    //         filterClient = '&usertype=' + $scope.filterObj;
    //         objectIndexForblank += $scope.filterObj;
    //     }
    //     if (typeof $scope.filterObj.brandId !== 'undefined' && $scope.filterObj.brandId !== null) {
    //         if (typeof $scope.filterObj.brandId._id !== 'undefined') {
    //             filterClient = filterClient + '&brandId=' + $scope.filterObj.brandId._id;
    //             objectIndexForblank += $scope.filterObj.brandId._id;
    //         } else {
    //             filterClient = filterClient + '&brandId=' + $scope.filterObj.brandId;
    //             objectIndexForblank += $scope.filterObj.brandId;
    //         }
    //     }
    // }
    if (options?.filterProject) {
        if (options.filterProject?._id) {
            filterClient = filterClient + '&projectId=' + options.filterProject._id;
        } else {
            filterClient = filterClient + '&projectId=' + options.filterProject;
        }
    }
    // if ($scope.listType === 'areachanneluser') {
    //     filterClient = filterClient + '&showareachannel=true';
    // }
    const urlPath = '/users/search/list?name=' + searchText + filterClient;
    return urlPath;
};

export interface User {
    genericFields?: {[key: string]: string};
    _id: string;
    firstName: string;
    lastName?: string;
    email?: string;
    profilePicture?: string;
    residentialAddress?: {
        city: string;
        address: string;
        zipCode: string;
        _country: string;
        loc: number[];
    };
}

export interface AsynchronousAutocompleteProps {
    label: string;
    helperTextSlug?: string;
    onChangeHandler?: any;
    filters: {
        filterProject?: string;
    };
    urlGenerator: (input: string) => string;
    initialValue?: any;
    multiple?: boolean;
    isDisabled?: boolean;
}

function AsynchronousAutocomplete({
    label,
    helperTextSlug,
    onChangeHandler,
    filters,
    urlGenerator,
    multiple,
    initialValue,
    isDisabled,
}: AsynchronousAutocompleteProps) {
    const sanitizeInitialValue = () => {
        if (multiple) {
            if (Array.isArray(initialValue)) return initialValue as User[];
            return [] as User[];
        } else {
            if (initialValue) return initialValue as User;
            return null;
        }
    };

    const { t } = useTranslation();
    const [open, setOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [filteredUsers, setFilteredUsers] = React.useState<readonly User[]>([]);
    const [value, setValue] = React.useState<User | User[] | null>(sanitizeInitialValue());
    const [inputValue, setInputValue] = React.useState('');

    React.useEffect(() => {
        let active = true;

        if (inputValue.length < 3) {
            return undefined;
        }
        if (!open) return undefined;

        (async () => {
            setLoading(true);
            const res = await API.get(urlGenerator(inputValue));
            const usersList = await res.json();

            if (active && Array.isArray(usersList)) {
                setFilteredUsers(usersList);
                setLoading(false);
            }
        })().catch(() => {
            setLoading(false);
        });

        return () => {
            active = false;
        };
    }, [inputValue]);

    React.useEffect(() => {
        if (!open) {
            setFilteredUsers([]);
        }
    }, [open]);

    return (
        <Autocomplete
            fullWidth
            disabled={isDisabled}
            multiple={multiple}
            open={open}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            value={value}
            onChange={(event: any, newValue: User | User[] | null) => {
                setValue(newValue);
                onChangeHandler && onChangeHandler(newValue, 'users');
            }}
            filterSelectedOptions={multiple}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
            }}
            isOptionEqualToValue={(option, value) => option._id === value._id}
            getOptionLabel={(option: User) =>
                `${option.firstName} ${option.lastName}${option.email ? ` (${option.email})` : ''}`
            }
            options={filteredUsers}
            noOptionsText={
                inputValue.length < 3 ? t('##StartTypingToSearch') : loading ? `${t('##Loading')}...` : t('##NothingFound')
            }
            loading={loading}
            renderInput={params => (
                <TextField
                    {...params}
                    label={label}
                    variant='standard'
                    helperText={helperTextSlug}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {loading ? <CircularProgress color='inherit' size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )}
        />
    );
}

interface UserSelectProps {
    onChange?: any;
    projectId?: string;
    multiple?: boolean;
    initialValue?: User | User[] | null;
    labelSlug?: string;
    helperTextSlug?: string;
    isDisabled?: boolean;
}

function UserSelect({
    onChange,
    projectId,
    multiple,
    initialValue,
    labelSlug,
    isDisabled,
    helperTextSlug,
}: UserSelectProps) {
    const { t } = useTranslation();

    return (
        <AsynchronousAutocomplete
            multiple={multiple}
            isDisabled={isDisabled}
            initialValue={initialValue}
            label={t(labelSlug || '##SearchUserName')}
            helperTextSlug={helperTextSlug ? t(helperTextSlug) : undefined}
            onChangeHandler={onChange}
            filters={{ filterProject: projectId }}
            urlGenerator={userUrlGenerator}
        />
    );
}

export default UserSelect;
