import {
    Autocomplete,
    AutocompleteRenderInputParams,
    Box,
    Chip,
    Popper,
    Portal,
    Snackbar,
    TextField,
    Typography,
    darken,
    lighten,
    styled,
} from '@mui/material';
import React, { useContext, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import API from '../../config/api';

import moment, { Moment } from 'moment';
import { getTextBoundingRect } from './utils';
import { isArray } from 'lodash';
import { RequestParams } from '../../config/api';
import { urlTypes } from './FilterFields/AutoCompleteUrlTypes';
import { EmplanFilterField, EmpplanFilterConfig } from './filterLine';
import { generateRandomKey } from '../CustomReportDependentFields/keygen';
import { ViewMagicSettings } from '../../hooks/useViewMagicSettings/viewMagicSettingAPI';

import MuiAlert, { AlertProps } from '@mui/material/Alert';
import EditableChip from './FilterFields/EditableChip';
import RenderInput from './FilterFields/RenderInput';
import { getFullAddress } from '../../components-app/location-view';
import { PWAContext } from '../../PWA/pwaContext';

const useStyles = () => ({
    boxStyles: {
        width: '100%',
        height: '100%',
        position: 'relative',
        border: 'none !important',
    },
    popperStyles: {
        transform: 'none !important',
        width: '100%',
        position: 'absolute !important',
        background: 'white',
    },
});
export interface IFilterChipProps {
    anchorEl: HTMLElement | null;
    filterConfig: EmpplanFilterConfig;
    viewMagicSettings: ViewMagicSettings<{
        listFilters: any;
        page: number;
        rowsPerPage: number;
    }> | null;
    updateViewMagicSettings: (settingObject: { listFilters: any; page: number; rowsPerPage: number }) => void;
    page: number;
    rowsPerPage: number;
    setFilterUrl: React.Dispatch<React.SetStateAction<RequestParams>>;
    disableClearable?: boolean;
    skipViewSettings?: boolean;
    injectOptionValue?: any;
    isFirstLoad?:boolean;
    viewSettingsReady?:boolean;
}
export interface SearchItem {
    _id: string;
    name: string;
}
interface IAutocompleteValue {
    label: string;
    name: IChipElement[] | string | IAutocompleteValue[];
    type: 'multi' | 'date'; // Add other possible types
    operators?: string[]; // Replace with actual type
    url?: string; // Replace with actual type
    options?: IChipElement[]; // Replace with actual type
    multiple?: boolean;
}
//Three FilterStep field name , operator,value
const FILTER_STEP_FIELD_NAMES = 'fieldNames';
const FILTER_STEP_OPERATOR_LIST = 'operatorList';
const FILTER_STEP_VALUE = 'fieldValue';
export function FilterChip({
    anchorEl,
    filterConfig,
    viewMagicSettings,
    updateViewMagicSettings,
    page,
    rowsPerPage,
    setFilterUrl,
    disableClearable,
    skipViewSettings,
    injectOptionValue,
    isFirstLoad,
    viewSettingsReady
}: IFilterChipProps) {
    const pwaContext = useContext(PWAContext);
    const styles = useStyles();
    const { t } = useTranslation();
    const [open, setOpen] = useState<boolean>(false);
    const [openCalender, setOpenCalender] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>(false);
    const [dateFromMobile, setDateFromMobile] = useState<boolean>(false);
    const [selected, setSetSelected] = useState<any[] | undefined>([]);
    const [inputValue, setInputValue] = useState<string>('');
    const [innerInputValue, setInnerInputValue] = useState<string>('');
    const [innerValue, setInnerValue] = useState<IChipElement[]>();
    const [filterUpdate, setFilterUpdate] = useState<Symbol>();
    const [inputParam, setInputParam] = useState<string>('name');

    const [filterStep, setFilterStep] = useState<string>(FILTER_STEP_FIELD_NAMES);
    const [multiple, setMultiple] = useState<boolean>(false);
    const [filterType, setFilterType] = useState<string>('');
    const [urlType, setUrlType] = useState<string>('url');
    const [filteredList, setFilteredList] = useState<any[]>([]);
    const [operatorList, setOperatorList] = useState<IChipElement[]>([]);
    const [poperMargin, setPoperMargin] = useState<string>('0px');
    const [projectId, setProjectId] = useState<string>('');

    const [filterName, setFilterName] = useState<string>('');

    const filterBarRef = useRef<any>(null);
    const filterBarInputRef = useRef<any>(null);
    const filterBarEditRef = useRef<any>(null);
    const filterBarInputEditRef = useRef<any>(null);
    const innerAutosuggestRef = useRef<any>(null);
    const autocompleteRef = useRef<any>(null);
    const close1ref = useRef<any>(null);
    // const [dateRange, setDateRange] = useState<DateRange<Moment> | undefined>();
    const [fieldNames, setfieldNames] = useState<EmplanFilterField[]>(filterConfig.items);
    const [filterEditMode, setFilterEditMode] = useState<boolean>(false);
    const [openToast, setOpenToast] = useState(false);
    const [editChipIndex, setEditChipIndex] = useState<number>(-1);
    const [prefilteredList, setPreFilteredList] = useState<any>(
        Object.fromEntries(Object.keys(urlTypes).map(key => [key, []])),
    );

    const getChipLabel = (el: any) => (el && el.label ? el.label : el?.name ? el?.name : '') || '';

    const Alert = React.forwardRef<HTMLDivElement, AlertProps>((props, ref) => (
        // eslint-disable-next-line react/jsx-no-undef
        <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />
    ));
    const handleOpenToast = () => {
        setOpenToast(true);
    };

    const filterOutGroups = (array: any) => {
        //remove duplicate item if config is set for multiple
        let filteredArray = [];
        let seenNames = new Set();

        for (let i = 0; i < array.length; i++) {
            const item = array[i];
            if (i + 2 < array.length) {
                const group = [array[i], array[i + 1], array[i + 2]];
                const groupName = group[0].name;
                const isFirstVisible = group[0]?.isAlwaysVisible === true;

                if (isFirstVisible && !seenNames.has(groupName)) {
                    filteredArray.push(...group);
                    seenNames.add(groupName);
                } else if (!isFirstVisible) {
                    let isDuplicate = false;
                    for (let j = 0; j < filteredArray.length; j += 3) {
                        const existingGroup = [filteredArray[j], filteredArray[j + 1], filteredArray[j + 2]];
                        if (existingGroup.length === 3 && existingGroup[0].name === groupName) {
                            isDuplicate = true;
                            break;
                        }
                    }
                    if (!isDuplicate) {
                        filteredArray.push(...group);
                        seenNames.add(groupName);
                    }
                }

                i += 2; // Skip the next two items
            } else {
                filteredArray.push(item);
            }
        }
        return filteredArray;
    };

    const handleCloseToast = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenToast(false);
    };
    React.useEffect(() => {
        if (filterConfig.items) {
            setfieldNames(filterConfig.items);
        }
    }, [filterConfig.items]);
    const UrlGenerator = (switchUrl: string | RequestParams, inputParam: string, limit: boolean, searchText?: string) => {
        let urlConfig: RequestParams = { url: '', params: {} };
        if (typeof switchUrl === 'string') {
            urlConfig = urlTypes[switchUrl];
        } else {
            urlConfig = switchUrl;
        }
        if (urlType === 'eventLabel') {
            if (projectId) {
                urlConfig.params['project_id'] = projectId;
            } else {
                urlConfig.params['project_id'] = 'all';
            }
        } else {
            if (projectId) {
                urlConfig.params['projectId'] = projectId;
            }
        }

        if (limit) {
            urlConfig.params['datalimit'] = 15;
        }
        urlConfig.params[inputParam] = searchText;
        return urlConfig;
    };

    const renderSingleFilter = (data: any[]) => {
        const groupedChips: JSX.Element[] = [];
        for (let i = 0; i < data.length; i += 3) {
            const chipsInGroup = data.slice(i, i + 3).map((el: { _id: any }, ix: number) => {
                const chipIndex = i + ix + 3;
                const disableRemoveFilter = data[i]?.disableRemoveFilter || false;
                const currentValue = data[i + (ix + 3) - 3];
                const uniqueChipKey = `${i}-${ix}`;
                const commonChipProps = {
                    key: chipIndex,
                    label: pwaContext?.pwa ? getChipLabel(el) : addDotsAfterSecondComma(getChipLabel(el) || el),
                    style: {
                        margin: '1px',
                        backgroundColor: 'rgb(36,192,177)',
                        color: 'white',
                        fontWeight: 'bold',
                        marginBottom: '5px',
                        borderRadius: '16px',
                        marginLeft: '1px',
                        marginRight: '0px',
                    },
                };

                return (
                    <EditableChip
                        key={uniqueChipKey}
                        commonChipProps={commonChipProps}
                        sx={{
                            // maxWidth: pwaContext?.pwa ? chipIndex % 3 === 2 ? '150px' : 'auto' : 'auto',
                            '& .MuiChip-deleteIcon': {
                                color: 'white',
                            },
                        }}
                        style={{
                            ...commonChipProps.style,
                            borderTopRightRadius: chipIndex % 3 === 0 ? '0px' : chipIndex % 3 === 1 ? 'unset' : '16px',
                            borderBottomRightRadius: chipIndex % 3 === 0 ? '0px' : chipIndex % 3 === 1 ? 'unset' : '16px',
                            borderTopLeftRadius: chipIndex % 3 === 2 ? '0px' : chipIndex % 3 === 1 ? 'unset' : '16px',
                            borderBottomLeftRadius: chipIndex % 3 === 2 ? '0px' : chipIndex % 3 === 1 ? 'unset' : '16px',
                            marginRight: chipIndex % 3 !== 1 && chipIndex % 3 !== 0 ? '3px' : '0px',
                        }}
                        clickable={chipIndex % 3 !== 1 && chipIndex % 3 !== 0 ? true : false}
                        onDelete={
                            disableRemoveFilter
                                ? undefined
                                : chipIndex % 3 !== 1 && chipIndex % 3 !== 0
                                ? () => deleteChip(chipIndex)
                                : undefined
                        }
                        onClick={undefined}
                        onEdit={editChip}
                        chipIndex={chipIndex}
                        setFilterEditMode={setFilterEditMode}
                        updateEditChip={updateEditChip}
                        filterEditMode={filterEditMode}
                        handleOpenToast={handleOpenToast}
                        editChipIndex={editChipIndex}
                        disableRemoveFilter={disableRemoveFilter}
                        currentValue={currentValue}
                        close1ref={close1ref}
                        setDateFromMobile={setDateFromMobile}
                        {...getRenderInputElementProps(true)}
                    />
                );
            });

            const divKey = `wrapper-${i}`;
            groupedChips.push(
                <div key={divKey} style={{ display: 'inline-flex' }}>
                    {chipsInGroup}
                </div>,
            );
        }

        return <Box style={{ display: 'contents' }}>{groupedChips}</Box>;
    };

    React.useEffect(() => {
        if (fieldNames) {
            let newfieldNames = fieldNames.filter(function (el) {
                return !el.isHidden();
            });

            setfieldNames(newfieldNames);
        }
    }, []);

    React.useEffect(() => {
        if (injectOptionValue && injectOptionValue.length > 0) {
            let modifiedselected = selected || [];
            let existsFilter = modifiedselected.filter(function (o) {
                return o.name === injectOptionValue[0].name;
            });

            if (existsFilter.length > 0) {
                return;
            }

            modifiedselected = modifiedselected.concat(injectOptionValue);

            modifiedselected = modifiedselected.map(function (item) {
                let exists = fieldNames.filter(function (o) {
                    return o.name === item.name;
                });
                if (exists.length > 0) {
                    return exists[0];
                } else {
                    return item;
                }
            });
            modifiedselected = filterOutGroups(modifiedselected);
            // console.log('modifiedselected', modifiedselected);
            setSetSelected(modifiedselected);
            buildQueryParam(modifiedselected);
            setFilterUpdate(Symbol(''));
        }
    }, [injectOptionValue]);

    React.useEffect(() => {
        if (
            (typeof skipViewSettings === 'undefined' || !skipViewSettings) &&
            viewMagicSettings?.settingObject?.listFilters
        ) {
            let defaultFilters: any[] = viewMagicSettings?.settingObject?.listFilters;
            defaultFilters = defaultFilters.map(function (item) {
                let exists = fieldNames.filter(function (o) {
                    return o.name === item.name;
                });
                if (exists.length > 0) {
                    return exists[0];
                } else {
                    return item;
                }
            });

            defaultFilters = filterOutGroups(defaultFilters);
            console.log('defaultFilters', defaultFilters);
            setSetSelected(defaultFilters);

            buildQueryParam(defaultFilters);
        }
    }, [viewMagicSettings?.settingObject?.listFilters, filterConfig.items]);

    React.useEffect(() => {
        if (filterConfig?.defaultValue && isFirstLoad && viewSettingsReady) {
            let defaultFilters: any[] = filterConfig?.defaultValue;
            defaultFilters = defaultFilters.map(function (item) {
                let exists = fieldNames.filter(function (o) {
                    return o?.name === item?.name;
                });
                if (exists.length > 0) {
                    return exists[0];
                } else {
                    return item;
                }
            });
            defaultFilters = filterOutGroups(defaultFilters);
            setSetSelected(defaultFilters);

            buildQueryParam(defaultFilters);
            setFilterUpdate(Symbol(''));
        }
    }, [filterConfig.defaultValue, isFirstLoad,viewSettingsReady]);

    React.useEffect(() => {
        if (selected && selected?.length > 0 && selected.length % 3 === 0) {
            updateViewMagicSettings({ listFilters: selected, page: page, rowsPerPage: rowsPerPage });
            buildQueryParam(selected);
        }
    }, [filterUpdate]);
    const editChip = (chipIndex: number) => {
        let modifiedArray = selected || [];
        //first remove uncomplete pair
        if (selected && selected.length % 3 !== 0) {
            const itemsToRemove = selected.length % 3 === 1 ? 1 : 2;

            modifiedArray = modifiedArray?.filter((_, index) => index < modifiedArray.length - itemsToRemove);
        }

        setSetSelected(modifiedArray);
        const filterType = modifiedArray[chipIndex - 5]?.type || '';
        setFilterType(filterType);

        if (filterType === 'autosuggest') {
            setUrlType(modifiedArray[chipIndex - 5].url);
        }

        if (filterType === 'select') {
            setFilteredList(modifiedArray[chipIndex - 5]?.options || []);
        }

        if (modifiedArray[chipIndex - 5]?.multiple) {
            setMultiple(true);
        }
        setFilterEditMode(true);
        setFilterStep(FILTER_STEP_VALUE);
        setEditChipIndex(chipIndex - 3);
    };

    const updateEditChip = (value: any) => {
        if (filterEditMode) {
            let modifiedArray = selected || [];
            modifiedArray[editChipIndex] = value;
            setSetSelected(modifiedArray);
            setFilterUpdate(Symbol(''));
            setFilterEditMode(false);
            setFilterStep(FILTER_STEP_FIELD_NAMES);
            setEditChipIndex(-1);
            setFilteredList([]);
            setUrlType('url');
        }
    };
    const buildQueryParam = (values: any) => {
        let reqParam: RequestParams = {
            url: '',
            params: {},
        };

        if (values && values.length > 0 && values.length % 3 === 0) {
            for (let index = 0; index < values.length; index += 3) {
                const item = values[index];
                const valueIndex = index + 2;

                if (item.type === 'autosuggest') {
                    reqParam.params[String(item?.name)] = item.multiple
                        ? values[valueIndex].value
                        : values[valueIndex]?._id
                        ? values[valueIndex]?._id
                        : '';
                }

                if (item.type === 'select' || item.type === 'text') {
                    reqParam.params[String(item?.name)] = values[valueIndex].value.trim();
                }

                if (item.type === 'daterange') {
                    reqParam.params['date_start'] = values[valueIndex]?.date_start;
                    reqParam.params['date_end'] = values[valueIndex]?.date_end;
                    reqParam.params['date_stops'] = values[valueIndex]?.date_end;
                }
                if (item.name === 'activeFilterSave') {
                    reqParam.params['active'] = values[valueIndex].value;
                }

                reqParam.params[String(item?.name) + '_op'] = values[valueIndex - 1].mongoOperator;
            }
            setFilterUrl(reqParam);
        }
    };
    const deleteChip = (chipIndex: number) => {
        if (filterEditMode) {
            handleOpenToast();
            return;
        }
        if (selected && selected.length > 0 && selected.length % 3 === 0) {
            let removeItem1 = selected[chipIndex - 5];
            const updatedValue1 = selected?.filter(d => d._id !== removeItem1._id);
            let removeItem2 = selected[chipIndex - 4];
            const updatedValue2 = updatedValue1?.filter(d => d._id !== removeItem2._id);
            let removeItem3 = selected[chipIndex - 3];
            const updatedValue = updatedValue2?.filter(d => d._id !== removeItem3._id);
            setSetSelected(updatedValue);
            setFilterUpdate(Symbol(''));
            setFilteredList([]);
            setFilterEditMode(false);
            setUrlType('url');
            if (updatedValue?.length === 0) {
                setFilteredList([]);
                setFilterUrl({
                    url: '',
                    params: {},
                });
                updateViewMagicSettings({ listFilters: [], page: page, rowsPerPage: rowsPerPage });
                setSetSelected([]);
                setDateFromMobile(false);
                setFilterStep(FILTER_STEP_FIELD_NAMES);
                setPoperMargin(0 + 'px');
                setLoading(false);
                setMultiple(false);
                setUrlType('url');
            }
        }
    };
    const deleteFromSteptwo = () => {
        let modifiedArray = selected || [];
        if (filterEditMode) {
            modifiedArray = modifiedArray?.filter(
                (_, index) => index !== editChipIndex && index !== editChipIndex - 1 && index !== editChipIndex - 2,
            );
        } else {
            modifiedArray = modifiedArray?.filter((_, index) => index < modifiedArray.length - 2);
        }
        setSetSelected(modifiedArray);
        setFilterStep(FILTER_STEP_FIELD_NAMES);
        setLoading(false);
        setMultiple(false);
        setFilteredList([]);
        setFilterEditMode(false);
        setUrlType('url');
        const ele = innerAutosuggestRef.current.getElementsByClassName('MuiAutocomplete-clearIndicator')[0];
        if (ele) ele.click();
    };

    const setMultiFieldValue = () => {
        if (selected && innerValue && isArray(selected) && isArray(innerValue)) {
            let currentvalue = selected;

            let multiValue = {
                label: innerValue.map((x: { name: any }) => x.name).join(', '),
                value: innerValue.map((x: { _id: any }) => x._id).join(','),
                type: 'multiple',
                _id: generateRandomKey(),
            };

            if (filterEditMode) {
                let modifiedArray = selected || [];
                modifiedArray[editChipIndex] = multiValue;
                setSetSelected(modifiedArray);
                setFilterUpdate(Symbol(''));
                setFilterEditMode(false);
                setFilterStep(FILTER_STEP_FIELD_NAMES);
                setEditChipIndex(-1);
                setFilteredList([]);
                setUrlType('url');
                return;
            }

            currentvalue.push(multiValue);

            setFilterStep(FILTER_STEP_FIELD_NAMES);
            setMultiple(false);
            setFilteredList([]);
            setUrlType('url');
            setFilterEditMode(false);
            setSetSelected(currentvalue);
            setFilterUpdate(Symbol(''));
            const ele = innerAutosuggestRef.current.getElementsByClassName('MuiAutocomplete-clearIndicator')[0];
            if (ele) ele.click();
        }
    };

    const updateCursorPosition = (event: any) => {
        if (event.target?.type === 'text' && !multiple) {
            let cordinates = getTextBoundingRect(event.target, event.target.selectionStart, event.target.selectionEnd);
            setPoperMargin(cordinates.left - 50 + 'px');
        }
    };

    const calenderCloseUpdate = (dateRange: Moment[]) => {
        setOpenCalender(false);

        if (dateRange && selected && isArray(selected)) {
            let currentvalue = selected;
            //console.log('dateFromMobile', dateFromMobile);

            if (dateFromMobile) {
                currentvalue = currentvalue?.filter((_, index) => index < currentvalue.length - 1);
            }
            //console.log('currentvalue', currentvalue);
            const dateValue = {
                label: dateRange[0]?.format('DD.MM.YYYY') + ' - ' + dateRange[1]?.format('DD.MM.YYYY'),
                date_start: dateRange[0]?.startOf('day').toDate(),
                date_end: dateRange[1]?.endOf('day').toDate(),
                type: 'date',
                _id: generateRandomKey(),
            };
            if (filterEditMode) {
                let modifiedArray = selected || [];
                modifiedArray[editChipIndex] = dateValue;
                setSetSelected(modifiedArray);
                setFilterUpdate(Symbol(''));
                setFilterEditMode(false);
                setFilteredList([]);
                setUrlType('url');
                setFilterStep(FILTER_STEP_FIELD_NAMES);
                setEditChipIndex(-1);
                return;
            }
            currentvalue.push(dateValue);
            setFilterStep(FILTER_STEP_FIELD_NAMES);
            setSetSelected(currentvalue);
            setFilterUpdate(Symbol(''));
            setFilteredList([]);
            setUrlType('url');
            setFilterEditMode(false);
        }
    };
    const callAPI = (input: string, limit: boolean) => {
        let active = true;
        let controller: any = null;
        if (input.length < 1 && !limit) {
            return undefined;
        }
        (async () => {
            setLoading(true);
            controller?.abort();
            controller = new AbortController();
            const responseList: any = await API.getReqestWithParams(
                UrlGenerator(urlType, inputParam, limit, input),
                controller.signal,
            );
            let finallist = [];
            if (urlType === 'project') {
                finallist = responseList.projects;
            } else if (urlType === 'leadStatusList') {
                finallist = responseList?.map((item: any) => {
                    const label = item.statusLabel 
                                        ? item.type === 'EMPPLAN' 
                                            ? t('' + item.slangText) 
                                            : t('' + item.statusLabel) 
                                        : t('' + item.custom_status);
                    item.name = label;
                    item.label = label;
                    return item;
                });
            } else if (urlType === 'eventType') {
                finallist = responseList?.map((item: any) => {
                    item.name = item.label;
                    return item;
                });
            } else if (urlType === 'eventLabel') {
                finallist = responseList?.data?.customs?.map(
                    (item: {
                        name: any;
                        statusLabel: string;
                        type: string;
                        label: any;
                        slangText: any;
                        custom_status: any;
                    }) => {
                        if (item.type === 'EMPPLAN') {
                            item.label = item.slangText ? t(item?.slangText) : item?.statusLabel;
                            item.name = item.slangText ? t(item?.slangText) : item?.statusLabel;
                        } else {
                            item.label = item.custom_status;
                            item.name = item.custom_status;
                        }
                        return item;
                    },
                );
            } else if (urlType === 'user') {
                finallist = responseList?.map((item: any) => {
                    item.label = item?.firstName + ' ' + item?.lastName + ' (' + item?.email + ')';
                    return item;
                });
                //This Only for CRM Associated User
                if(filterConfig.module == 'crmList'){
                    if(filterName === 'associatedby'){
                        finallist.unshift({
                            label: t('##Nobody'),
                            _id: 'nobody'
                        });
                    }
                }
            } else if (urlType === 'location') {
                finallist = responseList?.map((item: any) => {
                    item.label = getFullAddress(item);
                    return item;
                });
            } else if (urlType === 'crmAddresslist') {
                finallist = Array.isArray(responseList)
                    ? responseList.map((item: any) => {
                          const streetNumber = item?.address?.streetNumber || '';
                          const postal = item?.address?.zipCode || '';
                          const city = item?.address?.city || '';
                          item.label = streetNumber + ', ' + postal + ' ' + city;
                          item._id = streetNumber + ',' + postal + ',' + city;
                          return item;
                      })
                    : [];
                // put the search string at the start of the selection options
                finallist = [{ label: input, _id: input }, ...finallist];
            } else {
                finallist = responseList;
            }

            if (active && Array.isArray(finallist)) {
                if (innerValue) {
                    finallist = finallist.filter(function (objFromA) {
                        return !innerValue.find(function (objFromB) {
                            return objFromA._id === objFromB._id;
                        });
                    });
                }
                const newList = { ...prefilteredList, [urlType]: finallist };
                setPreFilteredList(newList);
                setFilteredList(newList[urlType].filter((item: any, idx: number) => idx < 200));
                //console.log('finallist', finallist);
                setLoading(false);
            }
        })().catch(() => {
            setLoading(false);
        });

        return () => {
            active = false;
        };
    };
    React.useEffect(() => {
        if (filterType === 'autosuggest') {
            setFilteredList([]);
            callAPI(inputValue, false);
        }
    }, [inputValue]);
    React.useEffect(() => {
        if (filterType === 'autosuggest') {
            setFilteredList([]);
            callAPI(innerInputValue, false);
        }
    }, [innerInputValue]);

    React.useEffect(() => {
        if (filterType === 'autosuggest') {
            setFilteredList([]);
            callAPI('', true);
        }
    }, [urlType]);

    React.useEffect(() => {
        if (!filterEditMode) {
            setFilteredList([]);
            setUrlType('url');
            setFilterStep(FILTER_STEP_FIELD_NAMES);
            setOpen(false);
        }
    }, [filterEditMode]);

    const PopperDropDown = function (props: any) {
        return (
            <div>
                <Popper com={<span>lol</span>} {...props} placement='auto' disablePortal={true} />
            </div>
        );
    };
    const isDateRangeVisible = filterStep === FILTER_STEP_VALUE && filterType === 'daterange';

    const isAutocompleteVisible = pwaContext?.pwa ?
        filterStep === FILTER_STEP_VALUE && (filterType === 'autosuggest') && multiple
        : filterStep === FILTER_STEP_VALUE && (filterType === 'select' || filterType === 'autosuggest') && multiple;

    const addDotsAfterSecondComma = (inputString: string): string => {
        if (inputString && inputString.length > 0) {
            // Find the index of the third comma
            const firstCommaIndex = inputString.indexOf(',');
            const secondCommaIndex = inputString.indexOf(',', firstCommaIndex + 1);
            const thirdCommaIndex = inputString.indexOf(',', secondCommaIndex + 1);

            if (thirdCommaIndex !== -1) {
                // Insert '...' after the third comma
                inputString = inputString.slice(0, thirdCommaIndex + 1) + '...' + inputString.slice(thirdCommaIndex + 1);
            }

            // Find the index of the dots and return the modified string
            const dotsIndex = inputString.indexOf('...');
            return dotsIndex !== -1 ? inputString.slice(0, dotsIndex) + '...' : inputString;
        } else {
            return '';
        }
    };

    const handleAutosuggestChange = (newValue: any) => {

        
        //Filter Next Step Logic

        switch (filterStep) {
            case FILTER_STEP_FIELD_NAMES:
                if (fieldNames.includes(newValue[newValue.length - 1])) {
                    //nothing to do
                } else{
                    return
                }
                setOperatorList(newValue[newValue.length - 1].operators);
                setFilterType(newValue[newValue.length - 1].type);
                setFilterName(newValue[newValue.length - 1].name);
                if (newValue[newValue.length - 1]?.type === 'autosuggest') {
                    setUrlType(newValue[newValue.length - 1].url);
                    setProjectId(newValue[newValue.length - 1]?.projectId ? newValue[newValue.length - 1]?.projectId : '');
                    setInputParam(
                        newValue[newValue.length - 1]?.inputParam ? newValue[newValue.length - 1]?.inputParam : 'name',
                    );
                    setFilteredList([]);
                    callAPI('', true);
                }
                if (newValue[newValue.length - 1]?.type === 'select') {
                    setFilteredList(newValue[newValue.length - 1]?.options);
                }
                if (newValue[newValue.length - 1]?.multiple) {
                    setMultiple(true);
                }
                if (newValue[newValue.length - 1]?.isAlwaysVisible) {
                    let newField = { ...newValue[newValue.length - 1] };
                    newField._id = generateRandomKey();
                    setfieldNames([...fieldNames, newField]);
                }

                if (newValue[newValue.length - 1].operators?.length === 1) {
                    setFilterStep(FILTER_STEP_VALUE);
                } else {
                    setFilterStep(FILTER_STEP_OPERATOR_LIST);
                }

                break;
            case FILTER_STEP_OPERATOR_LIST:
                //console.log(operatorList)
                //console.log(newValue)
                if (operatorList.includes(newValue[newValue.length - 1])) {
                    //nothing to do
                } else{
                    return
                }
                if (isDateRangeVisible) {
                    setOpenCalender(true);
                }
                setFilterStep(FILTER_STEP_VALUE);
                break;
            case FILTER_STEP_VALUE:
                if (typeof newValue[newValue.length - 3]?.callback === 'function') {
                    (newValue[newValue.length - 3]?.callback as Function)();
                }
                if (filterType === 'text') {
                    newValue[newValue.length - 1] = {
                        _id: generateRandomKey(),
                        label: newValue[newValue.length - 1],
                        value: newValue[newValue.length - 1],
                    };
                }
                setFilteredList([]);
                setUrlType('url');
                setFilterStep(FILTER_STEP_FIELD_NAMES);
                setOpen(false);
                setFilterEditMode(false);
                break;
            default:
                break;
        }

        //if single operator then auto select it
        if (newValue[newValue.length - 1]?.operators?.length === 1) {
            let modifiedArray = newValue || [];
            modifiedArray?.push(newValue[newValue.length - 1].operators[0]);
            setSetSelected([...modifiedArray]);
        } else {
            setSetSelected(newValue);
        }

        setFilterUpdate(Symbol(''));
    };

    const handleBackspaceCall = (event: any) => {
        setOpen(false);
        // console.log('backspacef new value', selected?.length);
        let modifiedArray = selected || [];
        //console.log(selected);
        if (selected && selected.length > 0 && selected.length % 3 === 0) {
            if (selected[selected.length - 3]?.disableRemoveFilter) {
                return;
            }
        } else if (selected && selected.length > 0 && selected.length % 3 === 1) {
            if (selected[selected.length - 2]?.disableRemoveFilter) {
                return;
            }
        } else {
            if (selected && selected.length > 0 && selected[selected.length - 1]?.disableRemoveFilter) {
                return;
            }
        }
        if (selected && selected.length > 0 && selected.length % 3 === 0) {
            modifiedArray = modifiedArray?.filter((_, index) => index < modifiedArray.length - 3);
        } else if (selected && selected.length > 0 && selected.length % 3 === 1) {
            modifiedArray = modifiedArray?.filter((_, index) => index < modifiedArray.length - 1);
        } else {
            modifiedArray = modifiedArray?.filter((_, index) => index < modifiedArray.length - 2);
        }

        setSetSelected(modifiedArray);
        setFilterUpdate(Symbol(''));
        setFilterStep(FILTER_STEP_FIELD_NAMES);
        setLoading(false);
        setMultiple(false);
        setFilteredList([]);
        setFilterEditMode(false);
        setUrlType('url');

        if (modifiedArray?.length === 0) {
            setFilteredList([]);
            setFilterUrl({
                url: '',
                params: {},
            });
            updateViewMagicSettings({ listFilters: [], page: page, rowsPerPage: rowsPerPage });
            setSetSelected([]);
            setDateFromMobile(false);
            setFilterStep(FILTER_STEP_FIELD_NAMES);
            setPoperMargin(0 + 'px');
            setLoading(false);
            setMultiple(false);
            setUrlType('url');
        }
    };

    const getRenderInputElementProps = (edit?: boolean) => {
        const renderElementProps = {
            isDateRangeVisible: isDateRangeVisible,
            openCalender: openCalender,
            calenderCloseUpdate: calenderCloseUpdate,
            deleteFromSteptwo: deleteFromSteptwo,
            setOpenCalender: setOpenCalender,
            filteredList: filteredList,
            isAutocompleteVisible: isAutocompleteVisible,
            innerInputValue: innerInputValue,
            innerValue: innerValue,
            setInnerInputValue: setInnerInputValue,
            setInnerValue: setInnerValue,
            setMultiFieldValue: setMultiFieldValue,
            innerAutosuggestRef: innerAutosuggestRef,
            filterBarRef: edit ? filterBarEditRef : filterBarRef,
            filterBarInputRef: edit ? filterBarInputEditRef : filterBarInputRef,
            filterStep: filterStep,
            updateCursorPosition: updateCursorPosition,
            filterType: filterType,
            loading: loading,
            handleBackspaceCall: handleBackspaceCall,
            inputValue: inputValue,
            setInputValue:setInputValue
        };

        return renderElementProps;
    };
    const renderInput = (params: AutocompleteRenderInputParams) => {
        const eleProps = getRenderInputElementProps();

        return <RenderInput editMode={filterEditMode} params={params} {...eleProps}></RenderInput>;
    };
    return (
        <>
            <Box sx={styles.boxStyles}>
                <Autocomplete
                    onBlur={() => {
                        setOpen(false);
                    }}
                    multiple
                    autoHighlight={true}
                    filterSelectedOptions
                    disableClearable={disableClearable || false}
                    // freeSolo={filterStep === FILTER_STEP_VALUE ? true : false} // This prevents the dropdown button
                    freeSolo={true}
                    open={open}
                    value={selected}
                    ref={autocompleteRef}
                    PopperComponent={PopperDropDown}
                    getOptionDisabled={option => option?.disabled}
                    onOpen={() => {
                        setOpen(true);
                    }}
                    onClose={() => {
                        if (!multiple) {
                            filterBarInputRef.current.dispatchEvent(new Event('click'));
                            setOpen(true);
                        } else {
                            setOpen(false);
                        }
                    }}
                    noOptionsText={
                        inputValue.length < 3
                            ? t('##StartTypingToSearch')
                            : loading
                            ? `${t('##Loading')}...`
                            : t('##NothingFound')
                    }
                    loading={loading}
                    renderInput={renderInput}
                    inputValue={inputValue}
                    onInputChange={(event, newInputValue) => {
                        setInputValue(newInputValue);
                    }}
                    renderTags={renderSingleFilter}
                    onChange={(event: any, newValue: any | null, reason) => {
                        if (newValue.length > 0) {
                            handleAutosuggestChange(newValue);
                        } else {
                            setFilteredList([]);
                            setFilterUrl({
                                url: '',
                                params: {},
                            });
                            updateViewMagicSettings({ listFilters: [], page: page, rowsPerPage: rowsPerPage });
                            setSetSelected([]);
                            setDateFromMobile(false);
                            setFilterStep(FILTER_STEP_FIELD_NAMES);
                            setPoperMargin(0 + 'px');
                            setLoading(false);
                            setMultiple(false);
                            setUrlType('url');
                        }
                    }}
                    filterOptions={(options, { inputValue }) => {
                        if(filterStep === FILTER_STEP_FIELD_NAMES){
                            options = options.filter((option) =>
                               option?.label?.toLowerCase?.()?.includes?.(inputValue?.toLowerCase?.())
                           )
                           return options
                        }else if (filterType === 'autosuggest'){
                            return options
                        }else{
                            return options
                        }
                    }}
                    options={
                        filterStep === FILTER_STEP_FIELD_NAMES
                            ? fieldNames
                            : filterStep === FILTER_STEP_OPERATOR_LIST
                            ? operatorList
                            : filterType === 'autosuggest' || filterType === 'select'
                            ? filteredList
                            : []
                    }
                    getOptionLabel={getChipLabel}
                    sx={{
                        '& .MuiInput-root::before': {
                            borderBottom: 'none !important',
                        },
                        '& .MuiInput-root::after': {
                            borderBottom: 'none !important',
                        },
                        minWidth: 0.25,
                    }}
                    componentsProps={{
                        paper: {
                            sx: {
                                display: isAutocompleteVisible ? 'none' : 'auto',
                                border: '1px solid lightgray',
                                width: pwaContext?.pwa ? 200 : 384,
                                marginLeft: pwaContext?.pwa ? 0 : poperMargin,
                            },
                        },
                    }}
                />
            </Box>
            <Portal>
                <Snackbar
                    anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                    open={openToast}
                    autoHideDuration={5000}
                    onClose={handleCloseToast}
                >
                    <Alert onClose={handleCloseToast} severity={'error'} sx={{ width: '100%' }}>
                        {t('##FirstCompleteEditField')}
                    </Alert>
                </Snackbar>
            </Portal>
        </>
    );
}
